<template>
  <div>
    <v-app-bar v-if="isAuth" color="primary" dark>
      <v-app-bar-nav-icon
        v-if="isDrawer"
        @click="drawer = true"
      ></v-app-bar-nav-icon>
      <v-toolbar-title>{{ titulo }}</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-menu :close-on-content-click="false" transition="scale-transition">
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            class="mx-2"
            fab
            dark
            small
            color="light-blue"
            v-bind="attrs"
            v-on="on"
          >
            <v-badge :content="leidos" :value="leidos" color="pink" overlap>
              <v-icon dark> mdi-bell </v-icon>
            </v-badge>
          </v-btn>
        </template>
        <v-card class="mx-auto" min-width="500" max-width="600">
          <v-card-text id="card-msg">
            <h4>SIN LEER ({{ leidos }})</h4>
            <v-spacer></v-spacer>
            <v-btn @click="checkall" small fab elevation="0">
              <v-icon
                center
                v-bind:style="leidos ? 'color: red' : 'color: green;'"
              >
                mdi-email-multiple
              </v-icon>
            </v-btn>
          </v-card-text>
          <v-virtual-scroll
            :items="notificacion"
            :item-height="60"
            height="185"
          >
            <template v-slot:default="{ item }">
              <v-divider></v-divider>
              <v-list-item
                two-line
                link
                :class="{ active: item.estado === true }"
                :key="item.id"
                @click="handleNotificationClick(item.aux)"
              >
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <v-list-item-content
                      v-on="on"
                      :class="{ 'color-text': item.estado === true }"
                    >
                      <v-list-item-title :title="item.mensaje"
                        >{{ item.mensaje }}
                      </v-list-item-title>
                      <v-list-item-subtitle v-if="item.mensaje2"
                        >{{ item.mensaje2 }}
                      </v-list-item-subtitle>
                    </v-list-item-content>
                  </template>
                  <span>{{ item.mensaje }} {{ item.mensaje2 }}</span>
                </v-tooltip>
                <v-list-item-action class="icon" id="icon2">
                  <v-btn small fab elevation="0" icon @click="check(item.id)">
                    <v-icon v-if="item.estado" color="green" center>
                      mdi-email-check-outline
                    </v-icon>
                    <v-icon v-else color="red" center>
                      mdi-email-remove-outline
                    </v-icon>
                  </v-btn>
                </v-list-item-action>
              </v-list-item>
            </template>
          </v-virtual-scroll>
          <v-divider></v-divider>
        </v-card>
      </v-menu>
      <v-menu left bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-btn v-bind="attrs" v-on="on" text>
            {{ user }} <v-icon right>mdi-account</v-icon>
          </v-btn>
        </template>
        <v-list>
          <v-list-item @click="profile">
            <v-list-item-avatar>
              <img src="../assets/avatar.png" alt="avatar" />
            </v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-title>{{ user }}</v-list-item-title>
              <v-list-item-subtitle>{{ rol }}</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
          <v-divider></v-divider>
          <v-list-item @click="clave">
            <v-list-item-title>Cambiar Clave</v-list-item-title>
          </v-list-item>
          <v-list-item @click="logout">
            <v-list-item-title
              >Salir <v-icon style="margin-left: 88px">mdi-exit-to-app</v-icon>
            </v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </v-app-bar>
    <v-navigation-drawer
      v-if="isDrawer"
      v-model="drawer"
      absolute
      temporary
      width="304"
    >
      <v-list>
        <v-list-item href="/">
          <v-icon class="mr-10"> mdi-view-dashboard </v-icon>
          <v-list-item-title>Dashboard</v-list-item-title>
        </v-list-item>

        <v-list-group
          v-for="item in items"
          :key="item.title"
          :prepend-icon="item.icon"
        >
          <template v-slot:activator>
            <v-list-item-title>{{ item.title }}</v-list-item-title>
          </template>

          <v-list-group
            v-for="child in item.items"
            :key="child.title"
            :prepend-icon="child.icon"
            no-action
            sub-group
          >
            <template v-slot:activator>
              <v-list-item-content>
                <v-list-item-title>{{ child.title }}</v-list-item-title>
              </v-list-item-content>
            </template>

            <v-list-item
              v-for="schild in child.items"
              :key="schild.title"
              :href="schild.href"
              link
            >
              <v-list-item-title v-text="schild.title"></v-list-item-title>

              <v-list-item-icon>
                <v-icon v-text="icon"></v-icon>
              </v-list-item-icon>
            </v-list-item>
          </v-list-group>
        </v-list-group>
        <v-list-item href="/">
          <v-icon class="mr-10"> mdi-cog-outline </v-icon>
          <v-list-item-title>Configuración</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>
  </div>
</template>
<script>
/* eslint-disable object-curly-newline */
import { API_URL } from '@/common/config';

const axios = require('axios');

export default {
  name: 'Navbar',
  data: () => ({
    drawer: false,
    group: null,
    isAdmin: false,
    isAuth: false,
    isDrawer: false,
    titulo: 'Mendoza Fiduciaria',
    mensajes: '0',
    user: '',
    rol: '',
    notificacion: [],
    icon: '',
    showPanel: true,
    items: [
      // Usuarios
      {
        title: 'Usuarios',
        icon: 'mdi-account',
        items: [
          {
            title: 'Usuarios',
            icon: 'mdi-account-circle',
            items: [
              { title: 'Nuevo', href: '/nuevo/usuarios' },
              { title: 'Lista', href: '/listado/usuarios' },
            ],
          },
          {
            title: 'Rol',
            icon: 'mdi-account-multiple',
            items: [
              { title: 'Nuevo', href: '/nuevo/roles' },
              { title: 'Lista', href: '/listado/roles' },
            ],
          },
        ],
      },
      // Maestros
      {
        title: 'Maestros',
        icon: 'mdi-table',
        items: [
          {
            title: 'Sectores Productivos',
            icon: 'mdi-cog-box',
            items: [
              { title: 'Nuevo', href: '/nuevo/sectoresProductivos' },
              { title: 'Lista', href: '/listado/sectoresProductivos' },
            ],
          },
          {
            title: 'Documentos',
            icon: 'mdi-book-open',
            items: [
              { title: 'Nuevo', href: '/nuevo/documentos' },
              { title: 'Lista', href: '/listado/documentos' },
            ],
          },
          {
            title: 'Categorias de Operatorias',
            icon: 'mdi-credit-card-search',
            items: [
              { title: 'Nuevo', href: '/nuevo/tipoServicios' },
              { title: 'Lista', href: '/listado/tipoServicios' },
            ],
          },
          {
            title: 'Parametros de Operatorias',
            icon: 'mdi-cog',
            items: [
              { title: 'Nuevo', href: '/nuevo/tipoParametros' },
              { title: 'Lista', href: '/listado/tipoParametros' },
            ],
          },
          {
            title: 'Configuración de Parametros',
            icon: 'mdi-cog',
            items: [
              { title: 'Nuevo', href: '/nuevo/servicioParametros' },
              { title: 'Lista', href: '/listado/servicioParametros/' },
            ],
          },
          {
            title: 'Estados',
            icon: 'mdi-state-machine',
            items: [
              { title: 'Nuevo', href: '/nuevo/solicitudEstado' },
              { title: 'Lista', href: '/listado/solicitudEstado/' },
            ],
          },
          {
            title: 'Creditos Estados',
            icon: 'mdi-state-machine',
            items: [
              { title: 'Nuevo', href: '/nuevo/creditoEstado' },
              { title: 'Lista', href: '/listado/creditoEstado/' },
            ],
          },
          {
            title: 'Config Notificaciones',
            icon: 'mdi-bell',
            items: [
              { title: 'Nuevo', href: '/nuevo/cfgNotificacion' },
              { title: 'Lista', href: '/listado/cfgNotificacion/' },
            ],
          },
          {
            title: 'Config Mensaje Notificaciones',
            icon: 'mdi-bell-ring',
            items: [
              { title: 'Nuevo', href: '/nuevo/cfgNotificacionMsg' },
              { title: 'Lista', href: '/listado/cfgNotificacionMsg/' },
            ],
          },
        ],
      },
      // Operaciones
      {
        title: 'Operaciones',
        icon: 'mdi-cards-variant',
        items: [
          {
            title: 'Unidad de gestión',
            icon: 'mdi-monitor-dashboard',
            items: [
              { title: 'Nuevo', href: '/nuevo/unidadGestion' },
              { title: 'Lista', href: '/listado/unidadGestion' },
            ],
          },
          {
            title: 'Operatoria',
            icon: 'mdi-credit-card',
            items: [
              { title: 'Nueva', href: '/nuevo/servicio' },
              { title: 'Lista', href: '/listado/servicio' },
            ],
          },
          {
            title: 'Documentos',
            icon: 'mdi-book-open',
            items: [
              {
                title: 'Vincular Documentación',
                href: '/nuevo/documentosFideicomiso',
              },
            ],
          },
        ],
      },
    ],
  }),
  computed: {
    leidos() {
      let count = 0;
      this.notificacion.forEach((element) => {
        if (element.estado === false) {
          count += 1;
        }
      });
      return count;
    },
  },
  methods: {
    logout() {
      localStorage.removeItem('token');
      localStorage.clear();
      this.$router.push('/login');
      this.isAuth = false;
    },
    clave() {
      this.$router.push('/Pass');
    },
    profile() {
      this.$router.push('/profile').catch(() => {});
    },
    validateAuth() {
      const tokenLS = localStorage.getItem('token');
      const rol = localStorage.getItem('rol');
      if (tokenLS !== null && tokenLS !== '') {
        if (rol === '1') {
          this.isDrawer = true;
        } else {
          this.isDrawer = false;
        }
        this.isAuth = true;
        this.traerNotificaciones();
        this.user = `${localStorage.getItem('email')}`;
        this.getRol();
        // this.rol = `${localStorage.getItem('rolDescripcion')}`;
      }
    },
    async traerNotificaciones() {
      const url = `${API_URL}api/notificaciones/user/${localStorage.getItem(
        'user_id'
      )}`;
      const header = {
        headers: {
          token: localStorage.getItem('token'),
        },
      };
      await axios
        .get(url, header)
        .then((response) => {
          if (response.data.status === 'success') {
            this.notificacion = response.data.notificaciones;
            this.mensajes = Object.keys(response.data.notificaciones).length;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    handleNotificationClick(legajo) {
      const id = parseInt(legajo, 10);
      if (!id) return;
      this.$router.push({
        name: 'Gestionar',
        params: {
          id,
        },
      });
      this.showPanel = false;
    },
    check(id) {
      axios
        .post(`${API_URL}api/notificaciones/check`, {
          token: localStorage.getItem('token'),
          notificacion_id: id,
        })
        .then((response) => {
          if (response.data.status === 'success') {
            this.traerNotificaciones();
          }
        });
    },
    checkall() {
      axios
        .post(`${API_URL}api/notificaciones/checkall`, {
          token: localStorage.getItem('token'),
          usuario_id: localStorage.getItem('user_id'),
        })
        .then((response) => {
          if (response.data.status === 'success') {
            this.traerNotificaciones();
          }
        });
    },
    async getRol() {
      const token = localStorage.getItem('token');
      if (!token) {
        this.$router.push('/login');
        return;
      }

      const url = `${API_URL}api/roles/${localStorage.getItem('rol')}`;
      const header = {
        headers: {
          token,
        },
      };

      try {
        const response = await axios.get(url, header);

        if (response.status === 200) {
          const data = response.data;
          if (data.rol && data.rol.length > 0) {
            this.rol = data.rol[0].nombre;
          } else {
            console.error(
              'Error: La respuesta del servidor no contiene el rol.'
            );
          }
        }
      } catch (error) {
        console.error(error);
        localStorage.removeItem('token');
        localStorage.clear();
        this.$router.push('/login');
      }
    },
  },
  beforeMount() {
    const rol = localStorage.getItem('rol');
    if (rol === '1') {
      this.isAdmin = true;
    }
    this.validateAuth();
    this.$bus.$on('login', () => {
      this.validateAuth();
    });
  },
};
</script>
<style>
#card-msg {
  display: flex;
  padding: 5px 20px 5px 32px;
}

#card-msg h4 {
  align-self: center;
}

#icon1 {
  margin: 7px;
}

#icon2 {
  margin: 0px;
}

.active {
  background-color: #e4effa;
}

.color-text {
  color: #1976d2;
}

.word {
  word-break: break-all;
}

.v-card--reveal {
  align-items: center;
  bottom: 0;
  justify-content: center;
  opacity: 0.5;
  position: absolute;
  width: 100%;
}
.v-application .mr-10 {
  margin-right: 30px !important;
}
</style>
